.row {
  background: var(--color-surface);
  text-color: var(--color-on-surface);

  display: flex;
  flex-direction: row;
  align-items: center;

  border: 2px solid var(--color-secondary);
  padding: 4px;
  height: 50px;

  transition: transform .25s;
}

.row-edit {
  transform: translate(16px, 0);
}

.row form {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

.row.header {
  font-weight: 600;
}

.date {
  flex-basis: 25%;
}

.name {
  flex-basis: 25%;
}

.preview-url {
  flex-basis: 25%;
}

.preview-url img {
  width: auto;
  height: -webkit-fill-available;
}

.actions {
  flex-basis: 25%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.action {
  cursor: pointer;
  border: 2px solid var(--color-primary);
  background-color: var(--color-teal);
  padding: 4px;
  margin-left: 4px;
  margin-right: 4px;
  transition: box-shadow .1s;
}

.action:hover {
  box-shadow: 2px 2px black;
}

.action-delete {
  border-color: var(--color-error);
  background-color: var(--color-error-varient);
}

.schedule {
  display: grid;
  grid-gap: .5rem;
}

.row-click-hover {
  cursor: pointer;
  transition: box-shadow .1s;
}

.row-click-hover:hover {
  box-shadow: 4px 4px black;
}

.schedule-new {
  justify-content: center;
}

.schedule-new-open {
  justify-content: unset;
  transform: translate(16px, 0);
}

.select-image-popup {
  position: absolute;
  width: 100px;
  background: white;
  border: 1px solid var(--color-secondary);
  padding: 4px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(25px, 1fr));
  grid-gap: .1rem;
  box-shadow: 4px 4px black;
}

.select-image-popup > img {
  width: 25px;
  height: auto;
  cursor: pointer;
  transition: transform .25s;
}

.select-image-popup > img:hover {
  transform: scale(1.2, 1.2);
}

.select-image-trigger {
  cursor: pointer;
}

.select-image {
  z-index: 10;
}

input {
  border-color: var(--color-primary);

}
