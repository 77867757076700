.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 1rem;
}

div.preview {
  border: 2px solid var(--color-secondary);
  padding: 4px;
  transition: box-shadow .1s;
}

div.preview > img {
  max-width: 200px;
  width: 100%;
  height: auto;
  max-height: 200px;
}

.preview-add {
  cursor: pointer;
}

.preview-add:hover {
  box-shadow: 4px 4px black;
}
